import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { OnRouteApiDecodedToken, OnRouteApiTokenResponse } from '@app/shared/models/api_interfaces';
import { environment as env } from '@environments/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DataDogService } from './datadog.service';
import jwtDecode from 'jwt-decode';
import { UserService } from './user.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OnRouteAuthService {
  localToken: string;

  /**
   * This Service is used to retrieve a local token from the OnRoute API
   */
  constructor(private apiService: ApiService, private dataDogService: DataDogService, private userService: UserService) {}

  // if you  need to test w a non-zonar user, you can use these creds:
  // travis.nelson.zonar@gmail.com
  // S+#v_fwwK(9<qx`>
  public authenticate(accountCode = null): Observable<any> {
    // This covers an edge-case where a zonar-user refreshes the page but selects a different account
    if (accountCode && sessionStorage.getItem('accountCode') !== accountCode) {
      sessionStorage.removeItem('accountCode');
      sessionStorage.removeItem('localToken');
    }
    if (sessionStorage.getItem('localToken') && sessionStorage.getItem('accountCode')) {
      this.localToken = sessionStorage.getItem('localToken');
      this.dataDogService.setContextProperty('accountCode', sessionStorage.getItem('accountCode'));
      this.userService.user.accountCode = sessionStorage.getItem('accountCode');
      return of(true);
    }

    return this.getOnRouteLocalToken(accountCode).pipe(
      map((resp: OnRouteApiTokenResponse) => {
        accountCode = jwtDecode<OnRouteApiDecodedToken>(resp.localToken).account;
        sessionStorage.setItem('localToken', resp.localToken);
        sessionStorage.setItem('accountCode', accountCode);
        return this.authenticate(accountCode);
      })
    );
  }

  getOnRouteLocalToken(accountCode = null): Observable<OnRouteApiTokenResponse> {
    let params = new HttpParams();
    if (accountCode) {
      params = params.set('zonar_account', accountCode);
    }
    const onRouteTokenUrl = `${env.onRouteApi.baseUrl}/v1/token`;
    return this.apiService.post(onRouteTokenUrl, {}, params);
  }
}
